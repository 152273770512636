@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Montserrat";
  color: #333;
  /* width: 100%; */
  /* align-self: center; */
}

svg{
  height: 20px;
  width: 20px;
}

/*---------------------------------------------- NAVIGATION BAR ----------------------------------------------*/
.navbar {
  /* width:100%; */
  padding: 8px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.links {
  margin-left: auto;
}
.navb h3{
  /* align-items: right; */
  margin-top: 10px;
  margin-left: 1100px;
  /* margin-bottom: 0px; */
}
/* .navbar .links img{
  margin-left: auto;
} */
/*how close the navigation buttons are from each other*/
.navbar a {
  margin-left: 14px;
  text-decoration: none;
  padding: 6px;
  cursor: pointer;

}
/*Changes color to the navigation buttons when hovering*/
.navbar a:hover {
  color: rgb(20, 203, 26);
  cursor: pointer;
}

.navbar img{
  width: 250px;
  height: 100px;
  display: flex;
}

/*---------------------------------------------- HOME PAGE ----------------------------------------------*/

.home{
  /* width: 35%; */
  display: flex; 
  flex-direction: row;
}

/*Home schedule date container*/
.home-container2{
  margin-left: 8%;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.home-container5{
  font-size: 20px;
  margin-top: 10px;
}
.home-container5 label{
  margin-right: 10px;
}
.home-container5 select{
  font-size: 18px;
  border: hidden;
  width: 18%;
}
.home-container-3{
  margin-left: 2%;
  display: flex;
  flex-direction: column;
}
.home-container-4 .stage-live button{
  background: #424240;
  color: #fff;
  /* border: 0; */
  padding: 8px;
  width: 100px;
  margin-top: 20px;
  /* margin-left: 90%; */
  margin-bottom: 10px;
  /* height: 30px; */
  border-radius: 8px;
  cursor: pointer;
}
.home-container-4{
  margin-left: 90%;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.home-date-sched {
  /* width: 50%; */
  margin-top: 2%;
  /* align-items: center; */
  display:inline-block;  
}
/* .home-date-sched-1 {
  /* width: 62.5%; */
  /* margin-left: 5%; */
  /* align-items: center; */
  /* display:inline-block;  */
/* }  */
.home-date-sched label{
  font-size: 25px;
  font-weight: bold;
  border: 2px #ddd solid;
  /* margin-top: 20px; */

  border:hidden;
  box-sizing:content-box;
  display:inline-block; 
}

.home-date-sched p{
  /* width: 100%; */
  font-size: 25px;
  font-weight: bold;
  border: 2px #ddd solid;
  margin-left: 60%;
  /* margin-top: 20px; */
  border:hidden;
  box-sizing:content-box;
  display:inline-block; 
}

.home h2{
  font-size: 30px;
  font-weight: bold;
  border: 2px #ddd solid;
  margin-left: 12%;
  /* margin-top: -100px; */
  border:hidden;
  /* box-sizing:content-box; */
  display:inline-block; 
}
.home-date-sched button{
  background: #424240;
  color: #fff;
  border: 0;
  padding: 8px;
  width: 100px;
  height: 30px;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 350px;
  margin-right: 10px;

  cursor: pointer;  
  /* display: fle; */
}

.announcements-home{
  
  margin-top:2%;
  margin-left: 2%;
  margin-right: 3%;
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
}

/*Display event list*/

.events-home{
  /* position: absolute; */
  height: 500px;
  margin-bottom: 30px;
  /* width: 50%; */
  margin-top: 5%;
  box-sizing: border-box;
  overflow-y: scroll;
  display: inline-block;
}

.events-home p {
  font-size: 20px;
  margin-top: 5px;
  margin-left: 85%;
  display:inline-block;
}
.events-home h2 {
  font-size: 20px;
  margin-left: 0px;
  display:inline-block;
}

.project-preview {

  padding: 10px 20px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.project-prev p{
  font-size: 20px;
}
.no-conf-event h3{
  margin-top: 40px;
  font-size: xx-large;
}
/*Project room & Speaker buttons */
.project-preview button {
  margin-bottom: 8px;
  background: #008DED;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 65%;
  margin-top: 8px;
  /* margin-right: -390px;
  margin-left: 390px; */
  display:inline-block;
}
/*Announcemnts Container*/
.home-container{
  /* margin-left: 70%; */
  display: flex;
  flex-direction: column;
}
/*Display announcement list*/
.home-container .ann-title{
  /* text-align: center; */
/* margin-left: 25px; */
  margin-left: 8%;

  display: flex;
}
.admin-ann {
  /* position: absolute; */
  width: 372px;
  /* width: 31%; */
  height: 490px;
  top: 100px;

  background: #B6B9BB;
  border: 1px solid #979797;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  /* display: flex; */
  display: inline-block;
  overflow-y: scroll;
}

.announce-body{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  padding: 10px 20px;
  margin: 10px 10px;
  border-bottom: 1px solid #fafafa;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}

.announce-body text{
  margin-left: 140px;
  display: inline-block;

}
.announce-body p{
  width: 300px;
}
/*Delete Announcement Button*/
.announce-body button{
  height: 25px;
  width:80px;
  /* margin-left: 10%; */
  /* margin-bottom: 20px; */
  background: #bc0707;
  color: #fff;
  border: 0;
  /* p
  adding: 8px 20px; */
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
}

.announce-footer{
  /* position: absolute; */
  width: 400px;
  /* left: 850px; */
  /* right: 8.5%; */

  top: 730px;
  display: flex;
  /* border-radius: 8px; */
}
.announce-pop{
  display: flex;
  flex-direction: row;
}
.announce-pop .popup-rooms{
  /* width: 30%; */
  /* width: 1000px; */
  /* margin-left: 0.7px; */
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}

.announce-footer input{
  width: 75%;
  height: 25px;
  border-radius: 3px;
}
/*Send announcement button*/
.announce-footer button{
  height: 25px;
  width:18%;
  /* margin-left: 10%; */
  margin-bottom: 20px;
  background: #008DED;
  color: #fff;
  border: 0;
  /* padding: 8px 20px; */
  border-radius: 8px;
  cursor: pointer;
}

/*---------------------------------------------- STAGE PAGE ----------------------------------------------*/
.stage{
  display:flex;
  flex-direction: column;
}
.stage-4{
  /* align-items: right; */
  display: flex;
  flex-direction: row;
}

.stage-1{
  margin-left: 50px;
  /* display: flex;
  flex-direction: column; */
}
.stage-2{
  align-items: center;
  display: flex;
  flex-direction: column;
}
.stage-2 .stage-live button{
  background: #424240;
  color: #fff;
  /* border: 0; */
  padding: 8px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 10px;
  /* height: 30px; */
  border-radius: 8px;
  /* cursor: pointer; */

}

.stage h2{
  font-size: 25px;
  font-weight: bold;
  border: 2px #ddd solid;
  margin-top: 10px;
  /* margin-left: 50px; */
  width: 10%;
  height: 30px;
  border:hidden;
  box-sizing:content-box;
  /* display:inline-block;  */
}
.stage-upcoming{
  /* position: absolute; */
  margin-top: 30px;
  margin-left: 3%;
  margin-right:8%;
  /* width:500px; */
  /* height: 125px; */
  font-size: 20px;
  /* top: 200px; */
  display:inline-block;
}
.stage-upcoming h3{
  /* align-c: center; */
  /* margin-left: 4%; */

  margin-left: 40%;
} 
.stage-announcements{
  /* .stage-upcoming{ */
    /* position: absolute; */
    /* width: 100%; */
    margin-top: 30px;
    /* height: 120px; */
    /* top: 200px; */
  /* align-items: center; */
  display: inline-block;
}
.stage-announcements .home-container{
  /* margin-left: 100%; */
  height: 300px;
  /* width: 100%; */
  /* display: flex;
  flex-direction: column; */
}
.stage-announcements .home-container .ann-title h2{
  margin-left: 18%;
  /* height: 190px; */
  /* display: flex;
  flex-direction: column; */
} 
/* .stage-announcements .ann-title h2{
  /* text-align: center; *
  margin-left: 25px;
  display: inline-block;
} */
/* 
.stage-announcements .admin-ann{
  position: absolute;
  width: 372px;
  height: 125px;
  left: 857px;
  top: 200px;

  background: #B6B9BB;
  border: 1px solid #979797;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); *
  border-radius: 5px;
  display: inline-block;
  overflow-y: scroll;
}
.stage-announcements .announce-footer{
  position: absolute;
  left: 857px;
  top: 325px;
  width: 372px;

}
.stage-announcements .announce-footer input{
  width: 82%;
  height: 30px;
  /* margin-bottom: 30px; *
}
.stage-announcements .announce-footer button{
  /* width: 90%; */
  /* position: absolute; *
  height: 30px;
  width: 18%;
  margin-bottom: 30px;
} */


/* .stage h3{
  font-size: 25px;
  font-weight: bold;
  border: 2px #ddd solid;
  margin-left: 29%;
  border:hidden;
  box-sizing:content-box;
  display:inline-block; 
 } */


/* .stage-upcoming{
  position: absolute;
  margin-left: 29%;
  height: 125px;
  top: 200px;
  display:inline-block;
} */

/*---------------------------------------------- STAGE BBB ----------------------------------------------*/
.bbb {
  width:100%;
  /* display: flex;
  justify-content: center; */
  margin-top: 30px;
  color: red;
  display: flex;
  flex-direction: column;
}
/* .temp{
  /* width: 100%; */
  /* margin-left: 5%; */
  /* margin-right: 5%; */
  /* color: blue; *
  display: inline-block;
} */
.iframe {
  display: inline-block;
  /* width: 100%; */
  margin-left: 5%;
  margin-right: 2%;
  margin-bottom: 20px;
  /* height: 100%; */
  /* border: 0; */
  height: 630px;
  /* width: 1120px; */
  overflow-y: scroll;
}

.BarContainer {
  height: 25px;
  width: 93%;
  background-color: rgb(128, 142, 133);
  border-radius: 50;
  /* margin-top: 15px; */
  margin-bottom: 2px;
  /* margin: 50; */
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 15px;

}
.BarContainer .progressBar span{
  font-size: 20px;
  color: white;
  font-weight: bold;
  /* background-color: white; */
}

.UpcomingEvents{
  /* height: 190px; */
  /* width: 500px;
  /* margin-bottom: 10px; *
  box-sizing: border-box;
  border-radius: 5px; */
  /* background-color: #888; */
    /* position: absolute; */
    /* width: 372px; */
    /* width: 31%; */
    /* height: 490px; */
    /* top: 100px; */
  
    background: #B6B9BB;
    border: 1px solid #979797;
    box-sizing: border-box;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    /* margin-bottom:30px; */
    /* display: flex; */
    /* display: inline-block; */
    /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
}

.event-item{
  /* height: 140px;
  width: 500px;
  border: solid black 1px;
  background-color: #979797;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 1.2px;
  margin-bottom: 0.001em;
  border-radius: 10px;
  color: var(--primary); */
  /* display: inline-block; */
  /* align-items: center; */
  width: 400px;
  background: #FFFFFF;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  margin-bottom:10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* margin-bottom: 50px; */
  /* padding: 5px 10px; */
  /* margin: 5px 5px; */
  /* border-bottom: 1px solid #fafafa; */
  /* box-shadow: 1px 3px 5px rgba(0,0,0,0.1); */
}

.event-item p{
  /* width: 100px; */
  margin-left: 75%;
  margin-bottom: 5px;
  /* font-family: 'DM Sans', sans-serif; */
  /* letter-spacing: 1.2px;
  font-size: 18px;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  color: black;
  /* display: flex;
  flex-direction: column; *
  align-items: right;
  text-align: right; */
}

.event-item span{
  /* font-size: ; */
  /* margin-bottom: 10px; */
  /* font-size: 20px;
  /* font-family: 'DM Sans', sans-serif; *
  letter-spacing: 1.2px;
  margin-bottom: 1rem;
  margin-left: 1rem;
  color: black; */
  /* margin-left: 140px; */
  display: inline-block;
}

.html-endMeeting {
  /* width:100%; */
  padding: 8px;
  margin-top: 15%;
  margin-left: 25%;
  /* display:inline-block;
  align-items: center; */
}

/*---------------------------------------------- ROOMS PAGE ----------------------------------------------*/
.rooms{
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* flex: 2; */
  /* display: inline-flexbox; */
}
.rooms-title-block{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.rooms-title-block h2{
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 80%;
  margin-bottom: 25px;
  display: inline-block;
}

.rooms-title-block .stage-live button{
  background: #424240;
  color: #fff;
  /* border: 0; */
  padding: 8px;
  width: 100px;
  margin-top: 20px;
  /* margin-left: 90%; */
  margin-bottom: 10px;
  /* height: 30px; */
  border-radius: 8px;
  cursor: pointer;
}

.pr-list{
  display: flex;
  flex-direction: row;
}
.room-list{
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  /* flex: 2; */
}
.popup-rooms{
  /* width: 30%; */
  /* width: 1000px; */
  /* margin-left: 0.7px; */
  display: flex;
  flex-direction: column;
}
.popup-rooms h3{
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.popup-rooms p{
  margin-left: 10px;

  margin-bottom: 20px;
}

.popup-rooms label{
  margin-left: 5px;

  /* margin-bottom: 20px; */
}


/* 
.project-rooms{
  /* width: 35%; *
  font-size: 16px;
  align-content: center;
  margin-left: 10%;
  margin-right: 50px;
  margin-top: 15px;
  margin-bottom: 20px;
  /* background: rgba(94, 236, 99, 0.816); *
  /* border: 1px solid #E5E5E5; *
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px; 
  display: flex;
  flex-direction: column;
} */
.project-rooms p{
    color: #ffffff;
    font-size: large;
}
.project-rooms img {
  width: 22px;
  height: 22px;
  /* color: white; */
  /* margin-left: 40px;
  margin-right: 2px; */
  margin-right: -190px;
  margin-left: 280px;
  /* margin-bottom: 10px; */
  /* font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px; */
}
.project-rooms text {
  font-size: 20px;
  text-align: right;
  color: #ffffff;
  /* margin-left: 5px;
  margin-right: 1px; */
  /* margin-bottom: 20px; */
  align-content: center;
align-items: center;
  margin-right: -250px;
  margin-left: 200px;}

.project-rooms a{
  display: inline-block;
  /* background: rgba(94, 236, 99, 0.816); */
  background-color: #25b12a;
  border: 1px solid #25b12a;
  width: 511px;

  height: 100px;
  /* margin-top: 10px; */
  /* margin-left:5px;  */
  text-decoration: none;
}
.project-rooms a:visited{
  background-color: rgb(146, 158, 161);
  border: 1px solid #939ca2;
  border-radius: 5px;
}

.project-rooms a:hover{
  background-color: rgb(30, 185, 228);
  border: 1px solid #008DED;
  border-radius: 5px;
}

/* .project-rooms text{

  margin-bottom: 20px;
} */

/*---------------------------------------------- CONFERENCE SCHEDULE ----------------------------------------------*/


/*--------Schedule main view--------*/
.schedule-prev h4{ 
  width: 50%;
  padding: 6px 10px;
  font-weight: normal;
  border:hidden;
  box-sizing: border-box;
  display:inline-block; 
}
.schedule-prev p {
  width: 150px;
  margin-bottom: 10px;
  margin-left: 25%;
  display:inline-block; 
}
.schedule-prev text {
  width: 90px;
  margin-bottom: 10px;
  margin-left: 20%;
  display:inline-block; 
}
.schedule-done h1, .review-page h2{
  padding: 6px 10px;
  margin: 10px 0;
  display:inline-block; 
}
.schedule-done h2{
  padding: 6px 10px;
  margin-left: 40%;
  margin-bottom: 20px;
  display:inline-block; 
}
.schedule-done button{
  margin-left: 75%;
  font-size: 15px;
  background: #25b12a;
  color: #fff;
  border: 0;
  padding: 8px;
  width: 100px;
  /* height: 30px; */
  border-radius: 8px;
  cursor: pointer;
}
.schedule-button button{
  margin-left: 75%;
  margin-top: 20px;
  font-size: 15px;
  background: #25b12a;
  color: #fff;
  border: 0;
  padding: 8px;
  width: 150px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
}
.schedule-button h2{
  margin-left: 40%;
  margin-top: 30px;
  /* font-size: 15px; */
  /* background: #25b12a; */
  /* color: #fff; */
 
}

/*--------Schedule Create view---------*/
.cal2{
  align-items: center;
  margin-top: 20px;
  /* margin-left: 50px; */
  display: flex;
  flex-direction: column;
}
.sched-buttons button{
  background: #535353;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 25px;
  margin-top: 10px;
  margin-right: 25px;

  /* height: 40px; */

}
.cal22{
  width: 700px; 
  height: 650px;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow: auto;
}
.conf-title{
  font-size: 25px;
  margin-bottom: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.schedule-container{
  margin-left: 100px;
  display: flex;
  flex-direction: column;
}
.schedule-container2{
  margin-right: 100px;
  display: flex;
  flex-direction: column;
}
.row-conf{
  align-content: center;
  display: flex;
  flex-direction: row;
}
.schedule-buttons button {
  background: #008DED;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  /* margin-left: 60px; */
  margin-top: 10px;
  align-content: center;
  /* display: flex;
  flex-direction: row; */
}
.scheduler{
  width: 600px; 
  display: flex;
}
.c2{
  width: 600px; 
  padding: 6px 10px;
  margin: 20px 25px;
  border-bottom: 1px solid #fafafa;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  box-sizing:content-box;  
  text-decoration: none; 
} 
.c2 a {
  margin-left: 6px;
  text-decoration: none;
  padding: 6px;
}
.c2 button{
  background: #008DED;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 70px;
  margin-top: 10px;
}
.c2 text{
  font-weight: bold;
}
.c2 p {
  margin-top: 8px;
  margin-bottom: 8px;
  font-style: italic;
}
/*--------Modify Event Information view---------*/
.Event-information, .Conference-information{
  display: flex;
}
.Event-information-calendar{
  width:50%;
  display: inline-block;
}
.addNewEvent, .addNewConference{
  /* width: 50%; */
  margin-right: 5%;
  margin-left: 5%;
  display: inline-block;
  text-align: center;
}
.addNewEvent label, .addNewConference label{
  text-align: left;
  display: block;
}
.addNewEvent h2, .Conference-information h2{
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.addNewEvent input, .addNewEvent textarea, .addNewEvent select, .addNewConference input {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: inline-block;
}
.addNewEvent button, .addNewConference button{
  margin-bottom: 8px;
  background: #bc0707;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
  display:inline-block;
}
/* REVIEW PAGE */
.review-page button{
  margin-left: 750px;
  font-size: 15px;
  background: #25b12a;
  color: #fff;
  border: 0;
  padding: 8px;
  width: 100px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
}

/*---------------------------------------------- CONFERENCE SCHEDULE ----------------------------------------------*/
.members-validation h1{
  margin-top: 20px;
  margin-bottom: 20px;
}

.display-members-validation button{
  margin-left: 30px;
  font-size: 15px;
  background: #25b12a;
  color: #fff;
  border: 0;
  padding: 8px;
  width: 100px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
}
.display-members-validation li{
  margin-left: 30px;
  margin-top: 20px; 
  margin-bottom: 20px;
  /* font-size: 15px; */
 
}

/*---------------------------------------------- SETTINGS ----------------------------------------------*/
.settings p {
  font-size: 20px;
  margin-left: 9%;
  margin-top: 15px;
}
.settings a {
  text-decoration: none;
  padding: 6px;
  /* display:inline-block; */
  /* width: 50px; */
  color: black;
}
/* .settings a:link{
   color: green;
} */
.settings a:visited{
  /* text-decoration: none; */
  color: rgb(20, 203, 26);
}
.settings a:hover{
  /* text-decoration: none; */
  color: #008DED;

}
.settings h1{
  margin-top: 10px;
  margin-left: 4%;
  /* font-size: 20px; */
}
.settings h2{
  margin-top: 30px;
  margin-left: 7%;
  /* font-size: 20px; */
}

/*---------------------------------------------- ACCOUNT ----------------------------------------------*/
.account h1{
  margin-top: 10px;
  /* font-size: 20px; */
}
.account h2{
  margin-top: 30px;
  /* font-size: 20px; */
}
.account label{
  margin-top: 10px;
  margin-left: 20px;
  width: 150px;
  padding: 4px 0px;
  /* margin: 6px 10px; */
  border: 2px #ddd solid;

  border:hidden;
  box-sizing: border-box;
  display: block;
  display:inline-block; 
}
.account p{
  margin-left: 20px;
  margin-top: 10px;
}
.account a{
  text-decoration: none;
  color:#008DED;
}

/*---------------------------------------------- STATS PAGE ----------------------------------------------*/
.stats label{
  /* margin-top: 20px; */
  margin-left: 70%;
}
.stats input{
  margin-top: 20px;
}
.stat-prog{
  width: 100%; 
  display: inline-block;
}
.stat-prog-bar {
  width: 50%;
  padding: 6px 10px;
  display:inline-block; 
}
.stat-prog p{
  width: 25%;
  padding: 6px 10px;
  /* margin: 10px 0; */
  margin-left: 10%;
  border:hidden;
  box-sizing: border-box;
  /* display: block; */
  display:inline-block; 
}
.stat-prog h3{
  font-weight: bold;
  margin-top: 30px;
  margin-left: 8%;
}
/*max value of progressbar*/
.progress-container{
  height: 20px;
  background-color: gray;
  border-radius: 10px;
  margin: 5px;
}

/*percentage of max value*/
.progress-filler{
  height: 100%;
  border-radius: inherit;
  text-align: center;
}
/*Value inside progress bar*/
.progress-label{
  padding: 5px;
  color: white;
  font-weight: bold;
}
.statsdate{
  width: 90%;
  margin-left: 5%;
}
/*Title & Date*/
.statsdate h2{
  width: 85%;
  padding: 6px 10px;
  margin: 10px 0;
  /* margin-left: 10%; */
  border:hidden;
  box-sizing: border-box;
  /* display: block; */
  display:inline-block; 
}
.statsdate h3{
  /* width: 25%; */
  padding: 6px 10px;
  margin: 10px 0;
  border:hidden;
  box-sizing: border-box;
  /* display: block; */
  display:inline-block; 
}
.stats-people{
  display: flex;
  /* flex:1; */
  flex-direction:column;
 
}
.stats-people-women{
  margin-left: 32%;
  /* flex:1; */
  display: inline-block;
  /* flex-direction: column; */
}
.stats-people-women text{
  font-weight: bold;
  margin-top: 10%;
  /* margin-bottom: 20%; */
  font-size: 35px;
  margin-left: 14%;
  justify-self: center;
}
.stats-people-men{
  /* flex:1; */
  display: inline-block;
  /* flex-direction: column; */
}.stats-people-other{
  /* flex:1; */
  display: inline-block;
  /* flex-direction: column; */
  /* align-self: center; */
}

/*----------------------------*/
.event-list-preview {
  padding: 10px 100px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);

}
.event-list-preview a{
  text-decoration: none; 
}


/*--------------SCHEDULE DAY FILE PAGE--------------*/

.schedule input, .schedule select{
  width: 50%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  /* display: block; */
  display:inline-block; 
  /* display: inline-block;  */
  /* height: 20px;
  width: 20px;
  background: #fff;
  border: 2px #ddd solid;
  margin-right: 10px; */
}
.schedule label{
  width: 40%;
  padding: 6px 10px;
  margin: 10px 0;
  border:hidden;
  box-sizing: border-box;
  /* display: block; */
  display:inline-block; 
} 
.schedule h2{
  margin-bottom: 30px;
}

.schedule-new-conference input{
  width: 20%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display:inline-block; 
}
.schedule-new-conference label{
  width: 20%;
  padding: 6px 10px;
  /* margin: 10px 0; */
  margin-left: 30%;
  border:hidden;
  box-sizing: border-box;
  /* display: block; */
  display:inline-block; 
} 
.schedule-new-conference h2{
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10%;
}
.schedule-new-conference button{
  margin-left: 42%;
  margin-top: 30px;
  font-size: 15px;
  background: #bc0707;
  color: #fff;
  border: 0;
  padding: 8px;
  width: 100px;
  height: 20px;
  border-radius: 8px;
  cursor: pointer;
}


.project-preview a {
  font-size: 25px;
  margin-left: 10px;
  text-decoration: none;
  padding: 6px;
  /* display:inline; */
  width: 50px;
  color: black;

}
.project-prev p{
  margin-top: 20px;
  margin-left: 90%;
}
/* .project-preview a:link{
  color: #c9330e;
}
.project-preview a:visited{
  color: #3535f1;
  background-color: #c9330e;
}
.project-preview a:hover{
  color: #10a015;
} */

.conference-list{
  max-width: 800px; 
  padding: 6px 10px;
  /* margin: 20px 25px; */
  margin-left: 3%;
  margin-top: 3%;
  border-bottom: 1px solid #fafafa;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  box-sizing:content-box;  
  text-decoration: none; 
} 
/* .conference-list a {
  margin-left: 6px;
  text-decoration: none;
  padding: 6px;
} */

.conference-list button{
  background: #008DED;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 55%;
  margin-top: 10px;
}

.conference-list p{
  margin-bottom: 10px;
}



/*---------------------------------------------- PROJECT ROOM PAGE ----------------------------------------------*/
.project-room{
  display:flex;
  flex-direction: column;
}
.p-room-1{
  margin-left: 70px;
  margin-right: 80px;

  display: flex;
  flex-direction: column;
}
.proj-room-adv-stud{
  display:flex;
  flex-direction: row;
}
.proj-room-advisor{
  margin-left: 10%;
}
.proj-room-2{
  width: 100%;
  display:flex;
  flex-direction: column;
}
/* .proj-room-1 { */
  /* width:100%; */
  /* display: flex;
  flex-direction: row; */
  /* align-items: center;
  align-content: center; */
/* } */
/* .proj-room-1 .stage-live-button button{
  margin-left: 50%;
} */

.proj-room-2 h1{
  color: #25b12a;
  margin-bottom: 10px;
  margin-top: 30px;
  /* margin-left: 30%;
  margin-right: 30%; */
  text-align: center;
}
.project-room h2{
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 40px;
}
.project-room h3, .project-room p{
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 80px;
  margin-right: 80px;
  text-align: justify;
}

.project-room li{
  /* font-size: 15px; */
  /* color: #f1356d; */
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 100px;
}

.proj-room-1 button{
  background: #008DED;
  color: #fff;
  padding: 8px;
  width: 150px;
  height: 30px;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 80px;
  cursor: pointer;
  font-weight: bold;
  border: 2px #ddd solid;
  box-sizing:content-box;
  display: inline-block;
}

.proj-info-button-room h2, .proj-info-button-room h3,.proj-info-button-room p {
  /* width: 100%; */
  margin-left: 100px;
}
.project-room .project-room-1 {
  width:100%;
  /* display: flex;
  justify-content: center; */
  /* margin-top: 30px; */
  /* color: red; */
  /* display: flex; */
  /* flex-direction: column; */
  display:flex;
  flex-direction: row;
}
.rooms-title-button-block{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proj-r-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.proj-r-1 .stage-live button{
  background: #424240;
  color: #fff;
  /* border: 0; */
  padding: 8px;
  width: 100px;
  margin-top: 20px;
  /* margin-bottom: 5px; */
  /* height: 30px; */
  border-radius: 8px;
  /* cursor: pointer; */

}
.room-upcoming{
  /* position: absolute; */
  /* margin-top: 10px; */
  margin-left: 3%;
  margin-right:8%;
  /* width:500px; */
  /* height: 125px; */
  font-size: 20px;
  /* top: 200px; */
  display:inline-block;
}
.room-upcoming h3{
  /* align-c: center; */
  /* margin-left: 4%; */

  margin-left: 40%;
} 
.room-upcoming .event-item{
  width: 500px;
}

.room-upcoming .UpcomingEvents .event-item p{
  /* align-c: center; */
  /* margin-left: 4%; */
  font-size: 15px;
  margin-right:10px;
  margin-left: 400px;
}
.room-upcoming .UpcomingEvents .event-item span{
  /* align-c: center; */
  /* margin-left: 4%; */
  /* font-size: 15px; */
  margin-right:10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.room-announcements{
  /* .stage-upcoming{ */
    /* position: absolute; */
    /* width: 100%; */
    margin-top: 20px;
    /* height: 120px; */
    /* top: 200px; */
  /* align-items: center; */
  display: inline-block;
}
.room-announcements .home-container{
  /* margin-left: 100%; */
  height: 300px;
  /* width: 100%; */
  /* display: flex;
  flex-direction: column; */
}
.room-announcements .home-container .announce-body{
  /* margin-left: 100%; */
  height: 100px;
  /* width: 100%; */
  /* display: flex;
  flex-direction: column; */
}
.room-announcements .home-container .ann-title h2{
  margin-left: 18%;
  /* height: 190px; */
  /* display: flex;
  flex-direction: column; */
} 
.room-announcements .home-container .admin-ann p{
  /* width:270px; */
  margin-left: 18px;
  /* height: 190px */
  /* display: flex;
  flex-direction: column; */
} 
.room-announcements .home-container .admin-ann .announce-body text{
  margin-left: 140px;
  /* display: inline-block; */
  /* height: 190px */
  /* display: flex;
  flex-direction: column; */
} 


/*--------------EVENT DETAIL PAGE--------------*/
.event-details h2{
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 10px;
  margin-top: 20px;
  /* display: inline-block; */
}
.event-details input{
  /* font-size: 20px;
  color: #f1356d; */
  margin-bottom: 10px;
  display: inline-block;
}

.event-details div{
  margin: 20px 0;
}

.event-details button{
  background: #f1356d;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  /* display: inline-block; */
}

/*---------------------------------------------- LOG IN ----------------------------------------------*/
.signIn {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.signIn label{
  text-align: left;
  display: block;
}

.signIn h2{
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 75px;
  display: flex;
  align-items: center;
 
  color:#000000;
  margin-bottom: 25px;
}

.signIn input{
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  /* box-sizing: border-box; */
  display: block;
}

.signIn button{
 
  background: #008DED;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 30px;
  cursor: pointer;

  width: 175px;
  height: 38px;
  top: 10px;
}

.signIn img{
  width: 250px;
  height: 100px;
  top: 103px;
  left: 564px;
  margin-bottom: 30px;
  /* margin-top: 40px; */
}

.recaptcha{
  
  top: 10px;
  margin: 20px 50px;
  /* border: 1px solid #ddd; */
  /* box-sizing: border-box; */
  /* display: block; */
}

/*---------------------------------------------- REGISTER ----------------------------------------------*/
/* .signupImage img{
  align-items: left;
  width: 250px;
  height: 100px;
  top: 6px;
  left: 6px;
} */

.generalInfoSignUp {
  /* max-width: 400px; */
  margin: 0 auto;
  /* margin-bottom: 20px; */
  /* margin-top: 0px; */
  text-align: center;
  width:500px;
  height: 720px;/* 400px; /* how wide will the border around the information will be*/
  /* height: 750px; how long will the border around the information will be */
  left: 401px;
  top: 158px;
  
  border: 1px solid #5353539a;
  box-sizing: border-box;
}

.generalInfoSignUp-student {
  /* max-width: 400px; */
  margin: 0 auto;
  /* margin-bottom: 20px; */
  /* margin-top: 0px; */
  text-align: center;
  width:500px;
  height: 550px;
  left: 401px;
  top: 158px;
  
  border: 1px solid #5353539a;
  box-sizing: border-box;
}
.generalInfoSignUp-verify{
  margin: 0 auto;
  /* margin-bottom: 20px; */
  /* margin-top: 0px; */
  text-align: center;
  width:500px;
  height: 850px;
  left: 401px;
  top: 158px;
  
  border: 1px solid #5353539a;
  box-sizing: border-box;
}
.generalInfoSignUp-acreated{
  margin: 0 auto;
  /* margin-bottom: 20px; */
  /* margin-top: 0px; */
  text-align: center;
  width:500px;
  height: 350px;
  left: 401px;
  top: 158px;
  
  border: 1px solid #5353539a;
  box-sizing: border-box;
}
.generalInfoSignUp label, .generalInfoSignUp-student label, .changePass label, .generalInfoSignUp-verify label{
  text-align: left;
  margin: 0px 20px; /*under_label left*/
  display: block;
}

.generalInfoSignUp h1,  .generalInfoSignUp-student h1, .generalInfoSignUp-verify h1, .generalInfoSignUp-acreated h1{
  margin-top: 30px;
  font-size: 20px;
  color:#000000;
  margin-bottom: 30px;
}
/* 
.generalInfoSignUp .Select{
  height: 20px;
} */

.account-created h1{
  margin-top: 30px;
  margin-left: 10px;
  font-size: 20px;
  color:#000000;
  margin-bottom: 30px;
}

.account-created p, .askChangePass p, .changePass p, .html-endMeeting p{
  /* margin-top: 30px; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  text-align: justify;
  /* font-size: 20px; */
  /* color:#f1356d; */
  /* margin-bottom: 30px; */
}

.generalInfoSignUp h2, .askChangePass h1, .changePass h1,  .generalInfoSignUp-student h2, 
.generalInfoSignUp-verify h2, .generalInfoSignUp-acreated h2, .html-endMeeting h1{
  /* font-size: 20px; */
  /* color:#f1356d; */
  margin-top: 30px;
  margin-bottom: 30px;
}

.generalInfoSignUp input, .generalInfoSignUp select, .askChangePass input, .changePass input,  
.generalInfoSignUp-student input,  .generalInfoSignUp-student select{
  width: 90%; /*represents how log the box will be visually*/
  padding: 6px 10px; /* 10px = where it starts writting inside box; 6px = how thick the box is (size) downwards*/
  margin: 0px 20px 10px; /* 0px = how close the box is to label (above box); 20px = how close box is to left margin; 10px = how close the box is to the next box (below box) */
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;

}

.generalInfoSignUp button, .askChangePass button, .changePass button{
  background: #bc0707;
  color: #fff;
  border: 0;
  padding: 8px;
  margin-bottom: 60px;
  /* width: 75px; */
  height: 38px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}
.generalInfoSignUp-student button, .generalInfoSignUp-verify button, .generalInfoSignUp-acreated button{
  background: #bc0707;
  color: #fff;
  border: 0;
  padding: 8px;
  margin-bottom: 60px;
  width: 75px;
  margin-right: 10px;

  margin-left: 10px;
  height: 38px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}

.signupButtons button{
  margin-right: 50px;
  margin-left: 50px;
  display:inline-block;
}

.askChangePass {
  /* max-width: 400px; */
  margin: 0 auto;
  margin-top: 0px;
  text-align: center;
  width:500px;
  height: 300px;/* 400px; /* how wide will the border around the information will be*/
  /* height: 750px; how long will the border around the information will be */
  left: 401px;
  top: 158px;
  
  border: 1px solid #5353539a;
  box-sizing: border-box;
}

.changePass {
  /* max-width: 400px; */
  margin: 0 auto;
  margin-top: 0px;
  text-align: center;
  width:500px;
  height: 450px;/* 400px; /* how wide will the border around the information will be*/
  /* height: 750px; how long will the border around the information will be */
  left: 401px;
  top: 158px;
  
  border: 1px solid #5353539a;
  box-sizing: border-box;
}
/* .dropdown-project select{
  /* width: 100%; *
  padding: 6px 10px;
  margin: 0 10px; /* above_block left under_block 
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
} */


/*.backB button{
  background: #E54242;
   /* margin-right: 1px; 
  /* margin-bottom: -10px;  *
} */




/*---------------------------------------------- SPONSORS ----------------------------------------------*/
.sponsors{
  /* margin-left:13%; */
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sponsors h1{
  /* position: absolute; */
  /* width: 477px;
  height: 45px;
  left: 450px;
  top: 300px; */
  /* font-family: 'Montserrat'; */
  /* margin-left: 25%; */
  /* margin-top: 8%; */
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  margin-top:3%;
  margin-bottom: 3%;
  /* margin-right: 10%; */
  /* line-height: 78px; */
  display: inline-block;
  /* align-items: center;
  text-align: center; */
  /* letter-spacing: -0.004em; */
  
  color: #000000;
}


.dropdown-project select{
  
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd; 
  box-sizing: border-box;
  display: block;

}
/* .dropdown-project h2{
  font-size: 20px;
  color:#f1356d;
  margin-bottom: 30px;
} */


.checklist input{
  /* font-size: 100px; */
  display: inline-block; 
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px #ddd solid;
  margin-right: 10px;

}


.verifying label{
  width: 35%;
  padding: 4px 0px;
  margin: 6px 10px;
  border: 2px #ddd solid;

  border:hidden;
  box-sizing: border-box;
  display: block;
  display:inline-block; 
}



/* @keyframes k-loading-animation  {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} */
/* 
.k-i-loading.k-example-loading {
    font-size: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(144,152,165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: "";
    box-sizing: inherit; */
    /* border-radius: 50%;
    border-width: .05em;
    border-style: solid;
    border-color: currentColor;
    border-top-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
    content: "";
}

.k-i-loading.k-example-loading::before {
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
    animation: k-loading-animation .7s linear infinite;
}

.k-i-loading.k-example-loading::after {
    margin-top: -0.25em;
    margin-left: -0.25em;
    width: .5em;
    height: .5em;
    animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
    min-height: 280px;
    align-content: flex-start;
}
.example-wrapper p, .example-col p {
    margin: 20px 0 10px;
}
.example-wrapper p:first-child, .example-col p:first-child {
    margin-top: 0;
}
.example-col {
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
    padding-bottom: 20px;
}
.example-config {
    margin: 0 0 20px;
    padding: 20px;
    background-color: rgba(0,0,0,.03);
    border: 1px solid rgba(0,0,0,.08);
}
.event-log {
    margin: 0;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    list-style-type: none;
    border: 1px solid rgba(0,0,0,.08);
    background-color: white;
}
.event-log li {
    margin: 0;
    padding: .3em;
    line-height: 1.2em;
    border-bottom: 1px solid rgba(0,0,0,.08);
}
.event-log li:last-child {
    margin-bottom: -1px;
} */

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}



:root {
  --primary-light: #39ddfa;
  --primary: #00abdf;
  --primary-dark: #008ada;
  
  --white: #FFFFFF;
  --greyLight-1: #ebf5fc;
  --greyLight-2: #d5e0f3;
  --greyLight-3: #c1d9f0;
  --greyDark: #646b8c;
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}


.dribbble {
position: fixed;
font-size: 3.6rem;
right: 2rem;
top: 2rem;
color: #EA4C89;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-40px) scale(.5);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}




/* #app {
  margin-top: 40px;
} */
/* 
  outline: none;
} */

#email:valid {
  color: green;
}
#email:invalid {
  color: red;
}



.form-sr-rp{
  height: 20px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background: #008DED;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
  border:hidden;
}

.tab {
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;
  /* background-color: #f1f1f1; */
  border:hidden;

}